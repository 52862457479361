'use client';
import { useRouter } from 'next/navigation';
import React, { useState, useEffect,ChangeEvent, FormEvent} from "react";
import Link from 'next/link';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import toast from "react-hot-toast";
import { toastStyles } from "@/conf/constants";
import Image from "next/image";
import { signIn } from 'next-auth/react';

export default function Page() {
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false); // State to manage login status

    const router = useRouter();

    const handleForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isLoggingIn) return; // Prevent multiple submissions

        setIsLoggingIn(true); // Set login status to true

        const result = await signIn('credentials', {
          redirect: false,
          identifier: email,
          password
        });

        if (result?.error) {
          setIsLoggingIn(false); // Reset login status on error
          if (result.error === 'CredentialsSignin') {
            toast.error('Incorrect username or password');
          } else {
            toast.error(result.error);
          }
        } else {
            router.push("/");
        }
    };

    return (
      <div className="mx-auto my-24">
        <form onSubmit={handleForm}>
          <Card className="mx-auto max-w-lg min-w-[24rem] md:w-[28rem] lg:w-[32rem] p-4 rounded-[30px] shadow-[0px_-1px_14.7px_7px_rgba(0,0,0,0.07)]">
            <img src="/logo.svg" className="mx-auto -my-10 w-48 -mb-14"/>
            <p className="w-fit mx-auto text-[12px] font-semibold">Powered by SkillBridge</p>
            <CardHeader className="p-4">
              <CardTitle className="font-raleway text-2xl">Login to your account</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="my-4">
                <Input className="px-4 py-6" id="email" name="email" type="email" onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} placeholder="Enter your email" required />
              </div>
              <div className="my-4 relative">
                <Input className="px-4 py-6" id="password" name="password" type={showPassword ? "text" : "password"} onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} placeholder="Enter your password" required />
                <div className='cursor-pointer absolute right-4 top-4' onClick={() => setShowPassword(prev => !prev)}>
                  <Image src={!showPassword ? '/openeye.svg' : '/closeeye.svg'} alt="show/hide password icon" width={20} height={20}/>
                </div>
              </div>
              <div className='flex justify-end items-center'>
              <p className="text-[#1570EF] text-sm inline text-right cursor-not-allowed">Forgot your Password?</p>
              </div>
              <Button type="submit" className="w-full text-lg py-6 rounded-md">
                Login now
              </Button>
              <p className="mx-auto w-fit text-[#98A2B3] text-sm">Don't have an account? <Link className="text-[#1570EF]" href="/signup">Sign Up</Link></p>
              <CardHeader className="p-1">
                <CardTitle className="font-raleway">Login using</CardTitle>
              </CardHeader>
              <div className="flex flex-wrap justify-between">
                <div onClick={() => {signIn('google')}} className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed"><Image src="/google.svg" alt="google_logo" width={26} height={26}/></div>
                <div className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed"><Image src="/apple.svg" alt="apple_logo" width={23} height={23}/></div>
                <div className="bg-[#ECF0F5] rounded-lg px-6 py-2 md:px-10 md:py-3 hover:bg-[#DDDFDD] cursor-not-allowed"><Image src="/microsoft.svg" alt="microsoft_logo" width={28} height={28}/></div>
              </div>
            </CardContent>
          </Card>
          <p className="text-[#98A2B3] text-center mt-2">
            <Link className='cursor-not-allowed' href="/#">Terms & Conditions &nbsp;</Link>
            |
            <Link className='cursor-not-allowed' href="/#">&nbsp; Privacy Policy</Link>
          </p>
        </form>
      </div>
    )
}
